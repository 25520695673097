import { delay } from "../_utils/helper"
import { Row, Col, Button } from "antd"
import { useEffect, useContext } from "react"
import { MyContext } from '../App'
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { useNavigate } from 'react-router-dom'

export function Setup1({ config }) {

      const { setAppData, UserData, setUserData } = useContext(MyContext)

      const navigate = useNavigate()

      useEffect(() => { if (!UserData.policy.rating.price.hasOwnProperty('total')) { navigate("/expired") } })

      useEffect(() => {

            // Do something on load 
            setAppData({ loading: false })

            // eslint-disable-next-line     
      }, [])

      const onCompleted = async () => {

            setAppData({ loading: true })

            await delay(0)

            const newData = {
                  title: "Mr",
                  firstName: "Oliver",
                  lastName: "Kingston"
            }

            setUserData({ insured: newData })
            navigate("/setup/two")
      }

      return (
            <>

                  {config.header.active && <Header config={config.header} />}

                  <Row id="Content">

                        <Col xs={config.grid.xs} sm={config.grid.sm} md={config.grid.md} lg={config.grid.lg} xl={config.grid.xl}>

                              <h1>Setup One</h1>
                              <p>Personal / business info goes here ..</p>
                              <Button type="primary" onClick={onCompleted}>Next</Button>

                        </Col>

                  </Row>

                  {config.footer.active && <Footer config={config.footer} />}

            </>



      )
}
