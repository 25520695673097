import { delay } from "../_utils/helper"
import { Row, Col, Button } from "antd"
import { useEffect, useContext } from "react"
import { MyContext } from '../App'
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { useNavigate } from 'react-router-dom'
import dayjs from "dayjs"

export function Review({ config }) {

      const { setAppData, UserData, setUserData } = useContext(MyContext)

      const navigate = useNavigate()

      useEffect(() => { if (!UserData.policy.rating.hasOwnProperty('sof')) { navigate("/expired") } })

      useEffect(() => {

            // Do something on load 
            setAppData({ loading: false })

            // eslint-disable-next-line     
      }, [])

      const onCompleted = async () => {

            setAppData({ loading: true })

            await delay(0)

            setUserData({ system: { session: { reviewed: dayjs().format("YYYY-MM-DD HH:mm:ss") } } })
            navigate("/pay")

      }

      return (
            <>

                  {config.header.active && <Header config={config.header} />}

                  <Row id="Content">

                        <Col xs={config.grid.xs} sm={config.grid.sm} md={config.grid.md} lg={config.grid.lg} xl={config.grid.xl}>

                              <h1>Review Stuff</h1>
                              <p>Last chance saloon  ..</p>
                              <Button type="primary" onClick={() => onCompleted()}>Happy. Let's pay</Button>

                        </Col>

                  </Row>

                  {config.footer.active && <Footer config={config.footer} />}

            </>



      )
}
