import { Row, Col, Button, Space } from "antd"
import { useEffect, useContext } from "react"
import { MyContext } from '../App'
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { useNavigate } from 'react-router-dom'

export function Driver({ config }) {

      const { setAppData } = useContext(MyContext)

      const navigate = useNavigate()

      const selectDriver = (driver) => {
            setAppData({ loading: true })
            navigate(`/quote/${driver}`)
      }

      useEffect(() => { setAppData({ loading: false }) }, [])

      return (
            <>

                  {config.header.active && <Header config={config.header} />}

                  <Row id="Content">

                        <Col xs={config.grid.xs} sm={config.grid.sm} md={config.grid.md} lg={config.grid.lg} xl={config.grid.xl}>

                              <h1>Driver</h1>
                              <p>Select driver type here ..</p>
                              <Space>
                                    <Button type="primary" onClick={() => selectDriver("courier")}>Courier</Button>
                                    <Button type="primary" onClick={() => selectDriver("remover")}>Remover</Button>
                                    <Button type="primary" onClick={() => selectDriver("other")}>Other</Button>
                              </Space>

                        </Col>

                  </Row>

                  {config.footer.active && <Footer config={config.footer} />}

            </>



      )
}
