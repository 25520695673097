import LazyLoad from "react-lazyload"
import { useNavigate } from 'react-router-dom'

export function Image({ src, width, alt = '', clickable = false, navigate }) {

      const link = useNavigate()

      return (

            <LazyLoad width={width || 200} offset={100}>

                  <img
                        src={`/assets/images/${src}`}
                        width={width || 'auto'}
                        alt={alt}
                        style={{ maxWidth: '100%' }}
                        className={clickable && "clickable"}
                        onClick={navigate ? () => link(navigate) : null}
                  />

            </LazyLoad>

      )
}
