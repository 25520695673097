import { useEffect, useReducer, createContext } from "react"
import { AppDefaultConfig, UserDefaultConfig } from "./_config/config"
import { deepMerge, sessionHandler } from "./_utils/helper"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { Home } from "./routes/Home"
import { Driver } from "./routes/Driver"
import { Lead } from "./routes/Lead"
import { Quote } from "./routes/Quote"
import { Setup1 } from "./routes/Setup1"
import { Setup2 } from "./routes/Setup2"
import { Review } from "./routes/Review"
import { Pay } from "./routes/Pay"
import { Complete } from "./routes/Complete"
import { Error } from "./routes/Error"
import { Expired } from "./routes/Expired"
import dayjs from "dayjs"


const env = window.location.hostname.includes("stage") || window.location.hostname.includes("local") ? 'stage' : 'prod'

export const MyContext = createContext()

export function App() {

      const [ AppData, setAppData ] = useReducer((oldData, newItem) => { return deepMerge(oldData, newItem) }, AppDefaultConfig[ env ])
      const [ UserData, setUserData ] = useReducer((oldData, newItem) => { return deepMerge(oldData, newItem) }, UserDefaultConfig)

      // AppData Changes
      useEffect(() => {
            // console.log(`AppData ${dayjs().format("HH:mm:ss.SSS")}`, JSON.stringify(AppData))
      }, [ AppData ])

      // UserData Changes
      useEffect(() => {
            console.log(`UserData ${dayjs().format("HH:mm:ss.SSS")}`, JSON.stringify(UserData))
            sessionHandler(UserData)
      }, [ UserData ])

      return (

            <MyContext.Provider value={{ AppData, setAppData, UserData, setUserData }}>
                  <Router>
                        <Routes>
                              <Route path="/" element={<Home config={{ ...AppData.pageDefault, ...{} }} />} />
                              <Route path="/driver" element={<Driver config={{ ...AppData.pageDefault, ...{} }} />} />
                              <Route path="/quote/other" element={<Lead config={{ ...AppData.pageDefault, ...{} }} />} />
                              <Route path="/quote/:driver" element={<Quote config={{ ...AppData.pageDefault, ...{} }} />} />
                              <Route path="/setup/one" element={<Setup1 config={{ ...AppData.pageDefault, ...{} }} />} />
                              <Route path="/setup/two" element={<Setup2 config={{ ...AppData.pageDefault, ...{} }} />} />
                              <Route path="/review" element={<Review config={{ ...AppData.pageDefault, ...{} }} />} />
                              <Route path="/pay" element={<Pay config={{ ...AppData.pageDefault, ...{} }} />} />
                              <Route path="/complete" element={<Complete config={{ ...AppData.pageDefault, ...{} }} />} />
                              <Route path="/expired" element={<Expired config={{ ...AppData.pageDefault, ...{} }} />} />
                              <Route path="/*" element={<Error config={{ ...AppData.pageDefault, ...{} }} />} />
                        </Routes>
                  </Router>
            </MyContext.Provider>

      )
}