
import { useContext } from "react"
import { MyContext } from '../App'
import { BiTransferAlt as Icon } from "react-icons/bi"

export function Loading() {

      const { AppData } = useContext(MyContext)

      if (AppData.loading) {

            return (

                  <Icon style={{ color: "#80D49C", fontSize: "34px" }} />

            )

      }
}
