import axios from "axios"

export function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
}

export function deepMerge(obj1, obj2) {
      var output = { ...obj1 }
      for (var key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                  if (typeof obj2[ key ] === "object" && !Array.isArray(obj2[ key ]) && obj2[ key ] !== null) {
                        output[ key ] = deepMerge(obj1[ key ], obj2[ key ])
                  } else {
                        output[ key ] = obj2[ key ]
                  }
            }
      }
      return output
}

export function sessionHandler(UserData) {
      localStorage.setItem(`bgSession`, JSON.stringify(UserData))
}

export function clearSession() {
      console.log('clearSession()')
      localStorage.removeItem("bgSession")
}

export async function post(url, d) {
      const res = await axios.post(url, d)
      return res.data
}

export function ucFirst(str) {
      str = str.toLowerCase()
      str = str.charAt(0).toUpperCase() + str.slice(1)
      return str
}