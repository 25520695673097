import { Row, Col, Button } from "antd"
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { useNavigate } from 'react-router-dom'

export function Expired({ config }) {

      const navigate = useNavigate()

      return (
            <>

                  {config.header.active && <Header config={config.header} />}

                  <Row id="Content">

                        <Col xs={config.grid.xs} sm={config.grid.sm} md={config.grid.md} lg={config.grid.lg} xl={config.grid.xl}>

                              <h1>Expired Session</h1>
                              <p>Your session expired. Please start again ..</p>
                              <Button type="primary" onClick={() => navigate("/driver")}>Start Again</Button>

                        </Col>

                  </Row>

                  {config.footer.active && <Footer config={config.footer} />}

            </>



      )
}
