import { Row, Col } from "antd"
import { Image } from "../components/Image"
import { Loading } from "../components/Loading"
import { FaEllipsisVertical as MenuIcon } from "react-icons/fa6"

export function Header({ config }) {

      return (

            <Row id="Header" justify="center" align="middle">

                  <Col xs={{ span: 22 }}>

                        <Row>

                              <Col flex="auto">

                                    <Image
                                          src={"logo.png"}
                                          width={175}
                                          alt="Boxguard Logo"
                                          clickable
                                          navigate="/"
                                    />

                              </Col>

                              <Col flex="100px" style={{ textAlign: "right" }}>

                                    <Loading />

                                    <MenuIcon
                                          size={35}
                                          className="clickable"
                                    />

                              </Col>

                        </Row>

                  </Col>

            </Row>

      )
}
