import { Row, Col, Divider } from "antd"
import { Image } from "../components/Image"

export function Footer({ config }) {

      return (

            <Row id="Footer" justify="center">

                  <Col xs={{ span: 18 }}>

                        <Row>

                              <Col span={12}>

                                    <Image
                                          src={"logo.png"}
                                          width={100}
                                          alt="Boxguard Logo Footer"
                                          clickable
                                          navigate="/"
                                    />

                                    <Divider />

                                    <p style={{ fontSize: "80%", fontWeight: 300 }}>Coversure Midlands Ltd t/a Boxguard is authorised and regulated by the Financial Conduct Authority (FCA). Registered Office: Coversure House, 72 Sherwood Road, Bromsgrove, B60 3DR. Registered in England No. 4981212</p>

                              </Col>

                              <Col flex="auto" style={{ textAlign: "right" }}>LINKS</Col>

                        </Row>

                  </Col>

            </Row>

      )
}
