import { Row, Col } from "antd"
import { useEffect, useContext } from "react"
import { MyContext } from '../App'
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"

export function Lead({ config }) {

      const { setAppData } = useContext(MyContext)

      useEffect(() => { setAppData({ loading: false }) }, [])

      return (
            <>

                  {config.header.active && <Header config={config.header} />}

                  <Row id="Content">

                        <Col xs={config.grid.xs} sm={config.grid.sm} md={config.grid.md} lg={config.grid.lg} xl={config.grid.xl}>

                              <h1>Other driver type</h1>
                              <p>Let's upsell a lead ..</p>

                        </Col>

                  </Row>

                  {config.footer.active && <Footer config={config.footer} />}

            </>



      )
}
