import { Row, Col, Button, message } from "antd"
import { defaultRfqCourier, defaultRfqRemover } from "../_config/config"
import { post, ucFirst } from "../_utils/helper"
import { useEffect, useContext, useState } from "react"
import { MyContext } from '../App'
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { useNavigate, useParams } from 'react-router-dom'

export function Quote({ config }) {

      const { driver } = useParams()

      const { AppData, setAppData, setUserData } = useContext(MyContext)
      const [ rfqData, setRfqData ] = useState(null)

      const navigate = useNavigate()

      const rfq = async (request) => {

            setAppData({ loading: true })

            const data = await post(`${AppData.api.base}/quote`, request)

            const { apikey, ...req } = request

            if (data.status === "success") {
                  setRfqData(data.result)
                  console.log(data.result)
                  setUserData({ policy: { rating: { request: req, price: data.result.price } } })
                  return

            } else {

                  message.error(JSON.stringify(data))

            }

            setAppData({ loading: false })

      }

      const onCompleted = async () => {
            setAppData({ loading: true })
            navigate("/setup/one")
      }

      useEffect(() => {
            (async () => { await rfq(driver === "courier" ? defaultRfqCourier : defaultRfqRemover) })()
            // eslint-disable-next-line
      }, [])

      return (
            <>

                  {config.header.active && <Header config={config.header} />}

                  <Row id="Content">

                        <Col xs={config.grid.xs} sm={config.grid.sm} md={config.grid.md} lg={config.grid.lg} xl={config.grid.xl}>

                              <h1>{ucFirst(driver)} Quote: {rfqData !== null ? rfqData.price.total : null}</h1>
                              <p>Play around with quote options here ..</p>
                              <Button type="primary" onClick={onCompleted}>Proceed & buy {rfqData !== null ? rfqData.price.total : null}</Button>

                        </Col>

                  </Row>

                  {config.footer.active && <Footer config={config.footer} />}

            </>



      )
}
