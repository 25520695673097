import React from 'react'
import ReactDOM from 'react-dom/client'
import 'antd/dist/reset.css'
import './style.css'
import { App } from './App'
import { AwsRum } from 'aws-rum-web'

try {
      const config = {
            sessionSampleRate: 1,
            guestRoleArn: "arn:aws:iam::731016139481:role/RUM-Monitor-eu-west-2-731016139481-3958156109961-Unauth",
            identityPoolId: "eu-west-2:8412022a-f16c-4834-9611-7296789bee70",
            endpoint: "https://dataplane.rum.eu-west-2.amazonaws.com",
            telemetries: [ "performance", "errors", "http" ],
            allowCookies: true,
            enableXRay: false
      }

      const APPLICATION_ID = '442e5f99-e6aa-4b8c-9a5d-ab97c748adc4'
      const APPLICATION_VERSION = '1.0.0'
      const APPLICATION_REGION = 'eu-west-2'

      const awsRum = new AwsRum(
            APPLICATION_ID,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            config
      )
} catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
      <App />
)
