import { Row, Col } from "antd"
import { clearSession } from "../_utils/helper"
import { useEffect, useContext } from "react"
import { MyContext } from '../App'
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { useNavigate } from 'react-router-dom'
import dayjs from "dayjs"

export function Complete({ config }) {

      const { setAppData, UserData, setUserData } = useContext(MyContext)

      const navigate = useNavigate()

      useEffect(() => { if (!UserData.payment.hasOwnProperty('id')) { navigate("/expired") } })

      useEffect(() => {

            setAppData({ loading: false })
            setUserData({ system: { session: { completed: dayjs().format("YYYY-MM-DD HH:mm:ss") } } })

            // eslint-disable-next-line     
      }, [])

      useEffect(() => {

            clearSession()

      }, [ UserData ])

      return (
            <>

                  {config.header.active && <Header config={config.header} />}

                  <Row id="Content">

                        <Col xs={config.grid.xs} sm={config.grid.sm} md={config.grid.md} lg={config.grid.lg} xl={config.grid.xl}>

                              <h1>Success</h1>
                              <p>Everything went well ..</p>

                        </Col>

                  </Row>

                  {config.footer.active && <Footer config={config.footer} />}

            </>



      )
}
