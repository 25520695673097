import dayjs from "dayjs"
import { v4 as uuid } from "uuid"

const APP_VERSION = "1.1.1"

const PAGE_DEFAULTS = {
      header: {
            active: true,
            sticky: true
      },
      footer: {
            active: true
      },
      grid: {
            xs: { span: 22, offset: 1 },
            sm: { span: 20, offset: 2 },
            md: { span: 18, offset: 3 },
            lg: { span: 16, offset: 4 },
            xl: { span: 16, offset: 4 }
      }
}

const LOADING = false

export const AppDefaultConfig = {
      stage: {
            version: APP_VERSION,
            pageDefault: PAGE_DEFAULTS,
            api: {
                  base: 'https://stage-api.dasher.uk'
            },
            loading: LOADING
      },
      prod: {
            version: APP_VERSION,
            pageDefault: PAGE_DEFAULTS,
            api: {
                  base: 'https://api.dasher.uk'
            },
            loading: LOADING
      }
}

// HANDLE USER CONFIGURATION

// USER ID
let USER_ID = uuid()
let USER_CREATED = dayjs().format("YYYY-MM-DD HH:mm:ss")

if (localStorage.getItem(`bgUser`) === null) {
      localStorage.setItem(`bgUser`, JSON.stringify({
            id: USER_ID,
            created: USER_CREATED
      }))
} else {
      const USER = JSON.parse(localStorage.getItem(`bgUser`))
      USER_ID = USER.id
      USER_CREATED = USER.created
}

// SESSION ID
const SESSION_ID = uuid()
const SESSION_CREATED = dayjs().format("YYYY-MM-DD HH:mm:ss")

// EXPORT THIS TO APP.JS
export const UserDefaultConfig = {
      system: {
            user: {
                  id: USER_ID,
                  created: USER_CREATED
            },
            session: {
                  id: SESSION_ID,
                  created: SESSION_CREATED
            }
      },
      policy: {
            rating: {
                  request: {},
                  price: {}
            }
      }

}

export const defaultRfqCourier = {
      apiKey: "f7fe33197652",
      driver: "C",
      cover: 10,
      vehicles: 1,
      pl: 0,
      el: 0,
      employees: 0,
      bar: 0,
      europe: 0,
      other_vehicles: 0
}

export const defaultRfqRemover = {
      apiKey: "f7fe33197652",
      driver: "R",
      cover: 10,
      vehicles: 1,
      pl: 0,
      el: 0,
      employees: 0,
      bar: 0,
      europe: 0,
      other_vehicles: 0
}